var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"groupbuy-list"},[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("團購中心")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("團購草稿")])],1)],1)])]),_c('b-card',[_c('div',{staticClass:"row d-flex mb-2 mb-xl-2 justify-content-between"},[_c('div',{staticClass:"col-6 d-flex align-items-center"},[_c('h4',{staticClass:"mb-2 mb-xl-0 mr-2 font-weight-bold"},[_vm._v("團購草稿")])]),_c('div',{staticClass:"col-6 d-flex justify-content-end"},[(_vm.checkPermission([_vm.consts.CORE_GROUPBUYING_CREATE]))?_c('b-button',{staticClass:"mb-2 mb-xl-0 ml-2 py-2",attrs:{"variant":"primary"},on:{"click":_vm.handleCreate}},[_c('i',{staticClass:"fa fa-plus",attrs:{"aria-hidden":"true"}}),_vm._v("我要開團")]):_vm._e()],1)]),_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.groupbuys,"fields":_vm.fields,"busy":_vm.isLoading},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(groupbuying_name)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(data.item.title)),_c('br'),_vm._v(_vm._s(data.item.groupbuying_number)+" ")])]}},{key:"cell(link)",fn:function(data){return [_c('b-link',{attrs:{"href":data.item.link,"target":"_blank"}},[_vm._v(_vm._s(data.item.link))])]}},{key:"cell(status)",fn:function(data){return [_c('div',[_vm._v(_vm._s(_vm.getStatusName(data.item.status)))])]}},{key:"cell(actions)",fn:function(data){return [(_vm.checkPermission([_vm.consts.CORE_GROUPBUYING_LIST_VIEW]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-primary","to":{
            name: 'CoreGroupbuyingsViewV2',
            params: {
              groupbuyID: data.item.id,
            },
          }}},[_c('span',{staticClass:"mdi mdi-eye mr-1"}),_vm._v("查看 ")]):_vm._e(),(_vm.checkPermission([_vm.consts.CORE_GROUPBUYING_MODIFY]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-warning","to":{
            name: 'CoreGroupbuyingsEditV2',
            params: {
              groupbuyID: data.item.id,
            },
          }}},[_c('span',{staticClass:"mdi mdi-lead-pencil mr-1"}),_vm._v("編輯 ")]):_vm._e(),(_vm.checkPermission([_vm.consts.CORE_GROUPBUYING_MODIFY]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-danger"},on:{"click":function($event){return _vm.handleDelete(data.item)}}},[_c('span',{staticClass:"mdi mdi-delete mr-1"}),_vm._v("刪除 ")]):_vm._e()]}}])}),_c('CustomPagination',{attrs:{"currentPage":_vm.query.page,"totalRows":_vm.total,"perPage":_vm.query.per_page},on:{"page-change":_vm.handlePageChange,"per-page-change":_vm.handlePerPageChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }